<template>
  <div id="gut-book-comp">
    <div class="container">
      <h2 class="mb-2">{{getBook.title}}</h2>
      <iframe width="100%" height="1000px" :src="getBook.formats['text/html; charset=utf-8']"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    getBook() {
      return this.$store.state.gutenbergBook;
    },
  },
};
</script>

<style lang="scss" scoped>
#gut-book-comp {
  height: 100vh;
}
</style>
