<template>
  <div>
    <div class="heading d-flex justify-content-between px-4">
      <h1 class="h1">
        {{ $t("comp_gutenbergBooks--title", { name: "Gutenberg" }) }}
      </h1>
    </div>
    <transition name="fade" mode="out-in">
      <div class="d-flex row px-4 main-wrap" v-if="!bookActive && isVisible">
        <!-- bootstrap flex card with button -->
        <div
          v-show="typeOfFile(book.formats['text/html; charset=utf-8'])"
          v-for="book in getBooks"
          :key="book.id"
          class="col-md-4 p-3 gutenberg-book"
        >
          <div
            class="card"
            v-if="typeOfFile(book.formats['text/html; charset=utf-8'])"
          >
            <div
              class="card-header"
              :style="`background: url(${book.formats['image/jpeg']}) no-repeat; background-size: cover; background-position: center;`"
              @click="openBook(book.id)"
            ></div>
            <div class="card-body">
              <div class="card-title head-title">
                {{ sanitizedBookTitle(book.title) }}
              </div>
              <h5 class="card-title py-2 book_body">{{ bookBody(book) }}</h5>
              <a class="card-link" @click="openBook(book.id)">Open</a>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="bookActive && isVisible">
        <div class="row">
          <div class="d-flex flex-row align-items-center">
            <a class="d-flex box-icon btn my-3 me-3" @click="backBook">
              <i class="bx bx-chevron-left box-icon"></i>
              <div>Back</div>
            </a>
          </div>
        </div>
        <div class="row">
          <GutenBergBook />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GutenBergBook from '@/common/widgets/GutenbergLibraryWidget/GutenbergBook.vue';

export default {
  name: 'GutenbergLibrary',
  components: {
    GutenBergBook,
  },
  data() {
    return {
      bookActive: false,
      isVisible: true,
    };
  },
  mounted() {
    this.$store.dispatch('gutenBergBooks');
  },
  computed: {
    getBooks() {
      const books = this.$store.state.gutenbergBooks;
      return books;
    },
  },
  methods: {
    openBook(id) {
      this.$store.dispatch('gutenBergBook', id);
      this.bookActive = true;
    },
    bookBody(book) {
      return book.subjects[0];
    },
    sanitizedBookTitle(title) {
      const containsColon = title.includes(':');
      if (containsColon) {
        const split = title.split(':');
        return split[0];
      }
      return title;
    },
    backBook() {
      this.bookActive = false;
    },
    typeOfFile(src) {
      if (typeof src === 'string') {
        return src.endsWith('.htm') || src.endsWith('.html');
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/abstracts/variables";
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.head-title {
  font-size: 0.6875rem;
  color: $black;
}
.book_body {
  font-size: 0.625rem;
  color: $black;
}
.card {
  flex-direction: row;
  border-radius: 10px;
  opacity: 1;
  height: 10em;
}
.card-link {
  position: absolute;
  bottom: 0;
  right: 0;
  background: $black;
  border-radius: 10px 0 10px 0;
  padding: 0.3em 0.7em;
  font-size: 0.625rem;
  color: $white;
  text-decoration: none;
  transition: all ease-in-out 200ms;
}
.card-link:hover {
  color: $lightGray;
  cursor: pointer;
}
.card-header {
  padding: 12px 7px;
  width: 40%;
  height: auto;
  transition: all ease-in-out 200ms;
}
.card-header:hover {
  cursor: pointer;
  transform: scale(1.1);
  width: 60%;
}
.card-body {
  width: 60%;
}
.gutenberg-book {
  min-width: 19rem;
}
</style>
